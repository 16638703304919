import React from 'react';

import formatClassNames from '../../../utils/formatClassNames'

import _defaultStyles from './buttons.module.scss';

const ButtonSubText = ({ children, ...props }) => {
  const { className = '', ...rest } = props;

  const classes = formatClassNames([_defaultStyles.buttonsubtext, className])

  return (
    <span className={classes} {...rest}>
      {children}
    </span>
  );
};

export default ButtonSubText;

import React from 'react';
import PropTypes from 'prop-types';

import SecondaryButton from '../buttons/SecondaryButton';
import ButtonWrapper from '../buttons/ButtonWrapper';
import ButtonText from '../buttons/ButtonText';
import ButtonSubtext from '../buttons/ButtonSubText';

import _buttonStyles from '../buttons/buttons.module.scss';
import _utils from '../../../styles/utils.module.scss';

const Submit = ({
  pending,
  disabled,
  idleText,
  idleIcon,
  idleSubtext,
  pendingText,
  pendingIcon,
  pendingSubtext,
}) => {
  return (
    <ButtonWrapper
      className={`${_utils.textCenter} ${_utils.mt16x} ${_utils.mb16x} ${_utils.pl16x} ${_utils.pr16x}`}
    >
      <SecondaryButton
        className={`${_utils.pt16x} ${_utils.pb16x} ${_utils.fullWidth} ${
          disabled ? _buttonStyles.disabled : ''
        }`}
        disabled={disabled}
        type="submit"
      >
        <ButtonText className={`${_utils.fontSize600} ${_utils.mb4x}`}>
          <span className={`${pending ? '' : _utils.hidden}`}>
            <i className={`fas ${pendingIcon}`}></i>
          </span>
          <span className={`${pending ? _utils.hidden : ''}`}>
            <i className={`fas ${idleIcon}`}></i>
          </span>
          <span>
            {pending ? pendingText : idleText}
          </span>
        </ButtonText>

        {/* Subtext for idle state */}
        {!pending && Boolean(idleSubtext) && (
          <ButtonSubtext>
            <em>{idleSubtext}</em>
          </ButtonSubtext>
        )}

        {/* Subtext for pending state */}
        {pending && Boolean(pendingSubtext) && (
          <ButtonSubtext>
            <em>{pendingSubtext}</em>
          </ButtonSubtext>
        )}
      </SecondaryButton>
    </ButtonWrapper>
  );
};

Submit.propTypes = {
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  pendingText: PropTypes.string,
  pendingSubtext: PropTypes.string,
  pendingIcon: PropTypes.string,
  idleText: PropTypes.string,
  idleSubtext: PropTypes.string,
  idleIcon: PropTypes.string,
};

Submit.defaultProps = {
  pending: false,
  disabled: false,
  pendingText: 'Pending...',
  pendingSubtext: '',
  pendingIcon: 'fa-spinner-third fa-spin',
  idleText: 'Idle...',
  idleSubtext: '',
  idleIcon: 'fa-arrow-right',
};

export default Submit;

import React from 'react';

import formatClassNames from '../../utils/formatClassNames'

import _defaultStyles from './text.module.scss';

const Highlighted = ({ children, ...props }) => {
  const { className = '', ...rest } = props;

  const classes = formatClassNames([_defaultStyles.highlighted, className])

  return (
    <span className={`${classes}`} {...rest}>
      {children}
    </span>
  );
};

export default Highlighted;

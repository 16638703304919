import { useQueryParam, StringParam } from "use-query-params";
import { removeEmpty } from 'kalanso-plugin-utils';

const useTrackingCodes = () => {

  // Mailchimp email campaign id
  const [mc_cid] = useQueryParam("mc_cid", StringParam);

  // Mailchimp unique email id
  const [mc_eid] = useQueryParam("mc_eid", StringParam);

  // This is what mailchimp uses as the value for mc_eid 
  // when you are previewing/testing an email
  // const TEST_EMAIL_ID = '[UNIQID]';

  // UTM Parameters (See: https://agencyanalytics.com/blog/utm-tracking)
  const [utm_source] = useQueryParam("utm_source", StringParam);
  const [utm_medium] = useQueryParam("utm_medium", StringParam);
  const [utm_campaign] = useQueryParam("utm_campaign", StringParam);
  const [utm_content] = useQueryParam("utm_content", StringParam);
  const [utm_term] = useQueryParam("utm_term", StringParam);

  // Zoom Webinar Id
  const [webinar_id] = useQueryParam("webinar_id", StringParam);
  // const [zm_wid] = useQueryParam("zm_wid", StringParam);

  // Stripe Coupon Id
  // const [coupon_id] = useQueryParam("coupon_id", StringParam);

  let nf_ab;
  
  // This is where we store the name of current branch. 
  // See: gatsby-plugin-netlify-ab
  if (typeof window !== 'undefined') {
    nf_ab = window.NF_AB;
  }

  const metadata = {
    mc_cid,
    mc_eid,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    webinar_id,
    nf_ab
  };
 
  return removeEmpty(metadata);
};

export default useTrackingCodes;
import  usePrevious from './usePrevious';

const usePreviousBlob = (data = {}) => {
  let currentBlob = {};

  try {
    currentBlob = JSON.stringify(data);
  } catch (error) {
    console.error(`Failed to stringify data...`)
  }

  const previousBlob = usePrevious(currentBlob);

  return [currentBlob !== previousBlob, currentBlob, previousBlob];
};

export default usePreviousBlob;
import React from 'react';
import PropTypes from 'prop-types';

import _themeFormStyles from '../forms.module.scss';
import _themeOrderFormStyles from '../../order-forms/order-form.module.scss';

import formatClassNames from '../../../utils/formatClassNames';

import { ErrorMessage } from '@hookform/error-message';
import { get } from 'react-hook-form';

const getTextFieldCSS = (name, values, errors, styles = []) => {
  const err = get(errors, name);
  const val = get(values, name);

  return formatClassNames([
    _themeFormStyles.input,
    err ? _themeFormStyles.invalid : '',
    !err && Boolean(val) ? _themeFormStyles.valid : '',
    ...styles
  ]);
};

const TextField = ({
  id,
  name,
  label,
  type,
  inputCss,
  fieldCss,
  labelCss,
  errorCss,
  ...rest
}) => {
  const { errors, values, register, ...inputProps } = rest;
  const registerRef = Boolean(register) ? register : null;

  return (
    <div
      className={`${_themeFormStyles.field} ${_themeOrderFormStyles.field} ${fieldCss}`}
    >
      <label htmlFor={id} className={`${_themeFormStyles.label} ${labelCss}`}>
        {label}
      </label>

      <input
        id={id}
        type={type}
        name={name}
        ref={registerRef}
        className={getTextFieldCSS(name, values, errors, [inputCss])}
        {...inputProps}
      />
      <div className={`${_themeFormStyles.errorMessage} ${errorCss}`}>
        <ErrorMessage errors={errors} name={name} />
      </div>
    </div>
  );
};

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  inputCss: PropTypes.string,
  fieldCss: PropTypes.string,
  labelCss: PropTypes.string,
  errorCss: PropTypes.string,
};

TextField.defaultProps = {
  type: 'text',
  inputCss: '',
  fieldCss: '',
  labelCss: '',
  errorCss: '',
  errors: {},
  values: {},
  register: () => {},
};

export default TextField;

import React from 'react';
import PropTypes from 'prop-types';

const Honeypot = ({ honeyId, register, ...props}) => {
  const registerRef = Boolean(register) ? register : null;

  return (
    <div
    style={{ position: 'absolute', left: '-20000px' }}
    aria-hidden={true}
  >
    <input
      id={honeyId}
      type="text"
      aria-hidden={true}
      aria-label="Honey"
      tabIndex="-1"
      name="honey"
      autoComplete={'off'}
      style={{ display: 'none' }}
      ref={registerRef}
      {...props}
    />
  </div>
  );
};

Honeypot.propTypes = {
  honeyId: PropTypes.string
};

Honeypot.defaultProps = {
  honeyId: 'honey'
};

export default Honeypot;

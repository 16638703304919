import React from 'react';
import PropTypes from 'prop-types';

import formatClassNames from '../../../utils/formatClassNames';

import _defaultStyles from './buttons.module.scss';

const SecondaryButton = ({ children, ...props }) => {
  const { className, isDisabled, ...rest } = props;

  const classes = formatClassNames([
    _defaultStyles.secondary,
    isDisabled ? _defaultStyles.disabled : '',
    className,
  ]);

  return (
    <button className={classes} {...rest}>
      {children}
    </button>
  );
};

SecondaryButton.propTypes = {
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

SecondaryButton.defaultProps = {
  type: 'button',
  className: '',
  isDisabled: false,
};

export default SecondaryButton;

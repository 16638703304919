import { useEffect, useCallback, useReducer } from 'react';

import usePrevious from './usePrevious';

const IDLE = 0;
const IN_FLIGHT = 1;
const SUCCESS = 2;
const ERROR = 3;

const INITIAL_STATE = {
  status: IDLE,
  payload: null,
  trigger: 0,
};

const fetchReducer = (state, action) => {
  if (action.type === 'RESET') {
    return { ...state, status: IDLE };
  }

  if (action.type === 'PENDING') {
    return {
      ...state,
      status: IN_FLIGHT,
      payload: null,
    };
  }

  if (action.type === 'SUCCESS') {
    return {
      ...state,
      status: SUCCESS,
      payload: action.payload,
    };
  }

  if (action.type === 'ERRORS') {
    return {
      ...state,
      status: ERROR,
      payload: action.payload,
    };
  }

  if (action.type === 'TRIGGER') {
    return { ...state, trigger: state.trigger + 1 };
  }

  return state;
};

const useFetch = (url, config = {}) => {
  const [state, dispatch] = useReducer(fetchReducer, INITIAL_STATE);

  const { trigger } = state;

  const makeRequest = useCallback(() => dispatch({ type: 'TRIGGER' }), [
    dispatch,
  ]);

  const reset = useCallback(() => {
    dispatch({ type: 'RESET' });
  }, [dispatch]);

  const prevTrigger = usePrevious(trigger);

  useEffect(() => {
    if (trigger === 0) return
    if (prevTrigger === trigger) return;

    dispatch({ type: 'PENDING' });

    fetch(url, config)
      .then((resp) => {
        if (!resp.ok) {
          return resp.json().then((errors) => Promise.reject(errors));
        }

        return resp.json();
      })
      .then((json) => {
        dispatch({ type: 'SUCCESS', payload: json });
      })
      .catch((errors) => {
        dispatch({
          type: 'ERRORS',
          payload: errors,
        });
      });
  }, [url, config, prevTrigger, trigger]);

  const { payload, status } = state;

  return [
    {
      idle: status === IDLE,
      pending: status === IN_FLIGHT,
      success: status === SUCCESS,
      errors: status === ERROR,
      payload,
    },
    makeRequest,
    reset,
  ];
};

export default useFetch;

import { useEffect, useState } from 'react';
import { canUseDOM } from 'kalanso-plugin-utils';

const useMouseInViewport = (windowHeight, windowWidth) => {
  const [inViewport, setInViewport] = useState(true);

  useEffect(() => {
    const handleMouseOut = (e) => {

      // Have we crossed height boundaries?
      if (e.clientY <= 0 || e.clientY >= windowHeight) {
        setInViewport(false);
      }

      // Have we crossed width boundaries?
      else if (e.clientX <= 0 || e.clientX >= windowWidth) {
        setInViewport(false);
      } 
      
      // We're still in the viewport
      else {
        setInViewport(true);
      }
    };

    if (canUseDOM()) {
      document.body.addEventListener('mouseout', handleMouseOut);
    }

    return () => {
      if (canUseDOM()) {
        document.body.removeEventListener('mouseout', handleMouseOut);
      }
    };
  }, [windowHeight, windowWidth]);

  return inViewport;
};

export default useMouseInViewport;

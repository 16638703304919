import React from 'react';

import formatClassNames from '../../utils/formatClassNames'

import _defaultStyles from './text.module.scss';
import _utils from '../../styles/utils.module.scss';

const Help = ({ children, ...props }) => {
  const { className = '', ...rest } = props;

  const classes = formatClassNames([_defaultStyles.help, _utils.textCenter, className])

  return (
    <p className={classes} {...rest}>
      {children}
    </p>
  );
};

export default Help;

import React from 'react';

import formatClassNames from '../../../utils/formatClassNames'

import _defaultStyles from './buttons.module.scss';

const ButtonWrapper = ({ children, ...props }) => {
  const { className = '', ...rest } = props;

  const classes = formatClassNames([_defaultStyles.wrapper, className])

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default ButtonWrapper;
